import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import Style from './index.module.scss'

const Page = () => (
  <div className={Style.markdownBody} style={{ margin: '16px' }}>
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>TermsOfService</title>
      <link
        rel="icon"
        type="image/x-icon"
        href={`${withPrefix('/')}img/recipe-note-icon.png`}
      />
    </Helmet>
    <h1>利用規約</h1>
    <section>
      <h2>第1条　はじめに</h2>
      <ol>
        <li>
          この利用規約（以下「本規約」といいます。）は、ユーザーが利用する場合の一切の行為に適用されます。
        </li>
        <li>
          本規約は、本サービスの利用条件を定めるものです。ユーザーは、本規約に同意の上、本規約に従い本サービスを利用するものとします。
        </li>
        <li>
          ユーザーは、本サービスを利用することにより、本規約の全ての記載内容について同意したものとみなします。
        </li>
      </ol>
    </section>
    <section>
      <h2>第2条　定義</h2>
      <p>
        本規約において使用する用語の意義は、次の各号に定めるとおりとします。
      </p>
      <ol>
        <li>「ユーザー」とは、本サービスを利用する者のことを意味します。</li>
        <li>
          「本サービス」とは、当社が運営している当サイトやアプリ等で提供するものを意味します。
        </li>
      </ol>
    </section>
    <section>
      <h2>第3条　適用</h2>
      <ol>
        <li>
          ユーザーは、本サービスの利用を開始する前に、本規約の内容をご確認の上、本規約に同意するものとします。なお、ユーザーが本サービスの利用を開始した時点で、ユーザーは本規約の内容を確認の上、これに同意したものとみなされます。ユーザーの本規約への同意により当社とユーザーの間に、本サービスの利用契約が成立します。なお、ユーザーが本規約に同意しない場合は、本サービスをご利用にならないでください。
        </li>
        <li>
          ユーザーが未成年者である場合には、親権者等の法定代理人の承諾を得て本サービスを利用するものとします。
        </li>
      </ol>
    </section>
    <section>
      <h2>第4条　本規約の変更</h2>
      <ol>
        <li>
          本規約は、当社の判断により、いつでも任意に変更することができるものとします。
        </li>
        <li>
          変更後の利用規約は、当社が別途定める場合を除き、本サイト及び関連サイト上に表示した時点より効力を生じるものとします。
        </li>
        <li>
          本規約の変更の効力が生じた後にユーザーが本サービスを利用した場合には、変更後の利用規約の全てにつき、同意したものとみなします。
        </li>
      </ol>
    </section>
    <section>
      <h2>第5条　個人情報の取扱い</h2>
      <ol>
        <li>
          当社は、個人情報を「プライバシーポリシー」に基づき、適切に取り扱うものとします。
        </li>
      </ol>
    </section>
    <section>
      <h2>第6条　本サービスの利用</h2>
      <ol>
        <li>
          ユーザーは、自己の責任において本サービスを利用するものとし、ユーザーは本サービスを利用してなされたインターネットからのデータ取得や保存といった一切の行為及びその結果について一切の責任を負います。
        </li>
        <li>
          本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、ユーザーの費用と責任において行うものとします。
        </li>
        <li>
          ユーザーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
        </li>
        <li>
          ユーザーは、当社が本サービス中に広告を掲載する場合があることを了承の上、本サービスを利用するものとします。
        </li>
        <li>
          ユーザーは、当社が当社の基準に基づき、本サービス上で配信する情報を取捨選択する場合があること及び当社が本サービス上で配信又は提供した情報について任意に配信若しくは提供を停止し又は削除する場合があることを了承の上、本サービスを利用するものとします。
        </li>
        <li>
          当社は、前項の当社の措置について、ユーザーに対し、何らの責任を負わないものとします。
        </li>
      </ol>
    </section>
    <section>
      <h2>第7条　権利帰属</h2>
      <p>
        本サービスを通じて提供される全てのデータ、文章、音声、映像、イラスト、情報及び本アプリに関する、著作権、商標権、肖像権を含む一切の権利は、当社又は当該権利を有する第三者に帰属します。
      </p>
    </section>
    <section>
      <h2>第8条　保存したレシピデータの権利・利用</h2>
      <ol>
        <li>
          レシピデータの保存に関する責任は、ユーザーに帰属します。当社はユーザーが本サービスを利用して保存したレシピの情報の内容について、一切責任を負いません。
        </li>
        <li>
          レシピデータの保管期間については、当社の裁量で定めることができるものとし、利用者はこれに異議を述べることができないものとします。
        </li>
        <li>
          ユーザーが他人の名誉を毀損した場合、プライバシー権を侵害した場合、許諾なく第三者の個人情報を開示した場合、著作権法に違反する行為を行った場合そのほか他人の権利を侵害した場合には、当該ユーザーは自身の責任と費用においてこれを解決しなければならず、当社は一切の責任を負いません。
        </li>
      </ol>
    </section>
    <section>
      <h2>第9条　禁止行為</h2>
      <p>
        ユーザーは、本サービスの利用にあたり、以下の各号に該当する事項を行ってはならないものとします。
      </p>
      <ol>
        <li>
          当社もしくは第三者の著作権、意匠権等の知的財産権、その他の権利を侵害する行為、又は侵害する恐れのある行為。
        </li>
        <li>
          本サイト及び関連サイトにアップロードされている投稿作品の情報を、当該著作者（創作者）の同意なくして転載する行為。
        </li>
        <li>
          当社もしくは第三者の財産、プライバシーもしくは肖像権を侵害する行為、又は侵害する恐れのある行為。
        </li>
        <li>
          当社もしくは第三者を不当に差別もしくは誹謗中傷し、第三者への不当な差別を助長し、又はその名誉もしくは信用を毀損する行為。
        </li>
        <li>詐称行為。</li>
        <li>詐欺その他の犯罪に結びつく、又は結びつく恐れのある行為。</li>
        <li>
          自殺・自傷行為・薬物乱用などを美化・誘発・助長する恐れのある行為。
        </li>
        <li>運営者に成りすます行為</li>
        <li>
          商業用の広告、宣伝又は勧誘を目的とする投稿作品の情報（当社が特に認めたものを除く。）、アフィリエイトのリンクを含む投稿作品の情報、MLM・リードメール等他人を勧誘する内容の投稿作品の情報、アダルトサイト・ワンクリック詐欺サイト・ウィルス等の有害なコンピュータプログラム等を流布させることを目的とするサイト等当社が不適切と判断するサイトに誘導する投稿作品の情報（単にリンクを張る行為を含む。）、その他当社が不適切と判断する投稿作品の投稿する行為。
        </li>
        <li>
          人種・民族・性別・年齢・思想などによる差別に繋がる表現を使用する行為。
        </li>
        <li>
          通常の範囲を超えて本サービスのサーバーに負担をかける行為、もしくは、本サービスの運営やネットワーク・システムに支障を与える行為、又はこれらの恐れのある行為。
        </li>
        <li>当社の設備に蓄積された情報を不正に書き換え、又は消去する行為。</li>
        <li>
          ウィルス等の有害なコンピュータプログラム等を送信又は掲載する行為
        </li>
        <li>
          法令、公序良俗又は本規約もしくはその他の利用条件等に違反し、又は他者の権利を侵害すると当社が判断する行為。
        </li>
        <li>その他、当社が不適切と判断する行為。</li>
      </ol>
    </section>
    <section>
      <h2>第10条　本サービスの停止等</h2>
      <ol>
        <li>
          当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
          <ul>
            <li>
              本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
            </li>
            <li>コンピューター、通信回線等が事故により停止した場合</li>
            <li>
              火災、停電、地震、天災地変などの不可抗力により本サービスの運営ができなくなった場合
            </li>
            <li>
              外部SNSサービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合
            </li>
            <li>その他、当社が停止又は中断を必要と判断した場合</li>
          </ul>
        </li>
        <li>
          当社は、当社が必要と判断した場合、本サービスの全部又は一部を変更、一時中止又は終了することができるものとします。
        </li>
        <li>
          当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。
        </li>
        <li>
          当社は、事前の予告なくして、本サービスをいつでも任意の理由で追加、変更、中断、終了することができます。
        </li>
        <li>
          当社は、本サービスの追加、変更、中断、終了に関し、一切の責任をおいません。
        </li>
      </ol>
    </section>
    <section>
      <h2>第11条　利用履歴等の閲覧、利用</h2>
      <ol>
        <li>
          当社は、ユーザーの本サービスの利用履歴（配信された情報のページビュー、クリック、各本サービスの利用状況を含みますが、これに限られません。以下同じとします。）、登録情報その他ユーザーの本サービス利用に際して当社が取得する情報を、個人を識別、特定できないように加工、集計及び分析した統計データ、属性情報等を作成し、当該履歴又は統計データ、属性情報等を本サービスの運営に必要な範囲で利用することができるものとし、ユーザーはこれをあらかじめ承諾します。
        </li>
        <li>
          当社は、ユーザーに適切な広告を配信するために必要な範囲で、利用履歴を第三者に提供することができるものとします。なお、提供する利用履歴は個人を識別、特定できないものであり、これにより当該第三者がユーザーの個人情報を取得することはありません。
        </li>
      </ol>
    </section>
    <section>
      <h2>第12条　免責事項</h2>
      <ol>
        <li>
          当社は、ユーザー間の通信等に関与しません。万一ユーザー間の紛争があった場合でも、当該ユーザー間で解決するものとし、当社はその責任を一切負いません。
        </li>
        <li>
          当社は、当社が必要と判断した場合は、ユーザーに通知することなくいつでも本サービスを変更、停止、又は中止することができます。この場合、当社は、本サービスの変更等により生じたいかなる損害についても、一切責任を負いません。アクセス過多、その他予期せぬ要因で表示速度の低下や障害等が生じた場合も同様とします。
        </li>
        <li>
          当社は、ユーザーによって取得される情報を管理・保存する義務を負いません。
        </li>
        <li>
          当社は、ユーザーによって取得される情報について、適法性、正確性等に関し一切の責任を負いません。ユーザーによって取得される情報が、当該ユーザーが所属する法人・団体等の内部規則等に関する適否についても、一切の責任を負いません。
        </li>
        <li>
          当社は、以下に掲げる場合には、当該サービスに保存した情報の内容を閲覧したり、保存したり、第三者に開示すること（以下、本項において「閲覧等」と言います。）ができるものとします。当社は、それによってユーザーに生じたいかなる損害についても、一切責任を負いません。
          <ul>
            <li>ユーザーが閲覧等に同意したとき。</li>
            <li>
              当社が閲覧等の同意を求める電子メールを送信してから7日以内に、当該ユーザーの電子メールでの回答が当社のメールサーバに到達しなかったとき。ただし、緊急止むを得ない事由が生じたときは除く。
            </li>
            <li>
              本サービスの技術的不具合の原因を解明し、解消するため必要な場合。
            </li>
            <li>
              裁判所、警察等の公的機関から、法令に基づく正式な照会を受けた場合。
            </li>
            <li>
              本規約、その他の利用条件等に違反する行為又はその恐れのある行為が行われ、保存情報の内容を確認する必要が生じたと当社が判断した場合。
            </li>
            <li>
              人の生命、身体及び財産などに差し迫った危険があり、緊急の必要性があると当社が判断した場合。
            </li>
            <li>
              その他本サイト及び関連サイト、アプリを適切に運営するために必要が生じた場合。
            </li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h2>第13条　本規約及びその他の利用規約等の有効性</h2>
      <ol>
        <li>
          本規約及びその他の利用条件等の規定の一部が法令に基づいて無効と判断されても、本規約及びその他の利用条件等のその他の規定は有効とします。
        </li>
        <li>
          利用規約等の規定の一部があるユーザーとの関係で無効とされ、又は取り消された場合でも、利用規約等はその他のユーザーとの関係では有効とします。
        </li>
      </ol>
    </section>
    <section>
      <h2>第14条　準拠法</h2>
      <ol>
        <li>本規約の準拠法は、日本法とします。</li>
      </ol>
    </section>
    <section>
      <h2>附則</h2>
      <ol>
        <li>本規約は、2020年7月1日から施行します。</li>
        <li>
          本規約の施行前にユーザーによって行われた行為についても本規約が適用されます。
        </li>
      </ol>
    </section>
  </div>
)
export default Page
